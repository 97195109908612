import { render, staticRenderFns } from "./publicFoot.vue?vue&type=template&id=29ef8452&scoped=true"
import script from "./publicFoot.vue?vue&type=script&lang=js"
export * from "./publicFoot.vue?vue&type=script&lang=js"
import style0 from "./publicFoot.vue?vue&type=style&index=0&id=29ef8452&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29ef8452",
  null
  
)

export default component.exports