<!--  -->
<template>
    <div class='foot'>
        <div class="bar" @click="goMePage(item.path)" v-for="(item) in footList" :key="item.name">
            <i :class="item.icon" class="icolor"></i>
            <p class="tabbar"> {{ item.name }}</p>
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
    // import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {
            footList: [
                {
                    name: '监控',
                    icon:"el-icon-video-camera-solid",
                    path: '/'
                },
                {
                    name: '我的',
                    icon:"el-icon-user-solid",
                    path: '/mePage'
                },
            ]
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        goMePage(path){
            this.$router.push({
                path
            })
        },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.foot {
    width: 100vw;
    height: 70px;
    display: flex;
    justify-content: space-around;
    // align-items: center;
    background: #3d88f5;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;

    .bar {
        display: flex;
        height: 50px;
        justify-content: space-around;
        align-items: center;
        background: #3d88f5;

        .tabbar {
            color: #fff;
            padding:0 0 0 3px;
            font-size: 18px;
        }

        .icolor {
            color: #fff;
            font-size: 18px;

        }
    }
}
</style>